@import "~bootstrap/scss/functions";

//@import "variables";

@import "~@solidwhite/vis-base/lib/sass/index.css";

/* Company colors */

$vp-blue: #3278be !important;
$vp-red: #ff0956 !important;
$vp-darkred: #ca1750 !important;
$vp-turq: #71cac4 !important;
$vp-darkturq: #30968f !important;
$vp-yellow: #e2e647 !important;
$vp-darkyellow: #d6db41 !important;

/* Page */

.bg-sso {
  background-color: $vp-blue;
}

body {
  background-color: white;
}

.page-title {
  color: #3278be;
}

a {
  color: #3278be;
}

.a:hover {
  color: #008bc7 !important;
  text-decoration: none;
}

.white-text {
  color: white !important;
}

.white-text p {
  color: white !important;
}

/*******
* Logo *
********/

.menu-logo-wrapper {
  height: 80px;
  margin: 20px;
  padding: 0;
  cursor: pointer;
}

.menu-logo-big {
  width: 260px;
  margin: 0;
}

.menu-logo-small {
  width: 40px;
  margin-bottom: 20px;
}

/* Buttons */

.btn-primary {
  color: white;
  background-color: #3278be;
  border-color: #3278be;
  border-radius: 0;
  outline: none;
}

.btn-primary:hover {
  color: white !important;
  background-color: #008bc7;
  border-color: #008bc7;
}

.btn-primary:focus {
  outline: 2px solid #008bc7 !important;
  background-color: #008bc7 !important;
  border-color: #008bc7 !important;
}

.btn-primary:active {
  outline: 2px solid #008bc7 !important;
  background-color: #008bc7 !important;
  border-color: #008bc7 !important;
}

.btn-primary:disabled {
  color: #e6e6e6;
  background-color: #b2b3b5;
  border-color: #b2b3b5;
}

.btn-secondary {
  color: #3f5761;
  background-color: white;
  border-color: #3f5761;
  border-radius: 0;
  outline: none;
}

.btn-secondary:hover {
  color: #768c97 !important;
  background-color: white;
  border-color: #768c97;
}

.btn-secondary:focus {
  color: #768c97 !important;
  background-color: white;
  outline: 2px solid #768c97 !important;
  border-color: #768c97 !important;
}

.btn-secondary:active {
  outline: 2px solid #768c97 !important;
  border-color: #768c97 !important;
}

.btn-transparent {
  color: $vp-blue;
  background-color: transparent;
  outline: none;
}

.btn-transparent:hover {
  color: $vp-red;
}

.download-btn-primary {
  color: white;
  background-color: #3278be;
  border-color: #3278be;
  border-radius: 0;
  outline: none;
}

.download-btn-primary:hover {
  color: white !important;
  background-color: #008bc7;
  border-color: #008bc7;
}

.download-btn-primary:disabled {
  color: #e6e6e6;
  background-color: #b2b3b5;
  border-color: #b2b3b5;
}

.download-btn-secondary {
  color: black;
  background-color: $vp-yellow;
  border-color: $vp-yellow;
  border-radius: 0;
  outline: none;
}

.download-btn-secondary:hover {
  color: black;
  background-color: $vp-darkyellow;
  border-color: $vp-darkyellow;
}

/* Badges */

.badge-primary {
  background-color: #3278be;
  color: white;
}

/* Others */

.link-text-secondary {
  color: #bbb !important;
}

.link-text-secondary:hover {
  color: #ddd !important;
  text-decoration: none;
}

.btn-link {
  color: #074a8a;
}

.btn-link:hover {
  color: #0f64b5 !important;
  text-decoration: none;
}

.spinner-border {
  color: #008bc7;
}

.imprint-link {
  color: #074a8a !important;
  text-decoration: none !important;
}

.widgetLink {
  color: $vp-blue;
}

.widgetLink:hover {
  color: #0f64b5;
}

.analytics-muted {
  color: #bbb;
}

/* Calendar */

.rdrSelected {
  color: #193461 !important;
}

.rdrDayPassive span {
  color: #ccc !important;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #ccc;
}

/* Menu first */

.menu-first {
  background: #fff;
  color: #333;
}

.menu-first .menu-link-wrapper {
  color: #333 !important;
}

.menu-first .menu-link-wrapper:hover {
  color: #3278be !important;
}

.menu-first .menu-link-wrapper.active {
  color: #3278be !important;
  border-right-color: #3278be !important;
}

.menu-first .menu-link-icon {
  color: #333 !important;
}

.menu-first .menu-link-text {
  color: #333 !important;
}

.base-version p {
  color: rgba(118, 140, 151, 0.5);
}

/* Menu second */

.menu-second {
  background: #f8f8f8;
  color: #333 !important;
}

.menu-second .menu-link-wrapper {
  color: #333 !important;
}

.menu-second .menu-link-wrapper:hover {
  color: #3278be !important;
}

.menu-second .menu-link-wrapper.active {
  color: #3278be !important;
  border-right-color: #3278be !important;
}

.menu-second .menu-link-icon {
  color: #333;
}

.menu-second .menu-link-text {
  color: #333;
}

/* Form controls */

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3278be !important;
}

// Outdated browser page:

#outdated {
  position: fixed;
  pointer-events: none;
  background-color: transparent !important;
  width: 100%;
  height: 100vh;
  z-index: 1500;
}

#outdated .vertical-center {
  pointer-events: all;
  padding-top: 4rem;
  width: 100%;
  height: 100vh;
  background-color: white !important;
  color: black !important;
  text-align: center;
}

#outdated h6 {
  color: black !important;
  font-size: 2rem;
}

#outdated p {
  display: none !important;
}

#buttonUpdateBrowser {
  background-color: white !important;
  display: none !important;
}
